import React from "react"
import { FaChevronCircleRight } from "react-icons/fa"

import Layout from "../components/layout"
import SEO from "../components/seo"

const RevocableLivingTrusts = () => (
  <Layout>
    <SEO title="Revocable Living Trusts" />
    <section className="section">
      <div className="container">
        <div className="columns">
          <div className="column is-full is-8-widescreen">
            <h1 className="title is-size-1">Revocable Living Trusts</h1>
            <p>
              People often say that they do not need a trust because they are
              not millionaires. The truth is, whether a person should have a
              trust often has little to do with the value of an estate. It has
              more to do with the kinds of assets they have and how they want
              them distributed, although they do have beneficial tax advantages
              for married couples with sizeable estates.
            </p>
            <h2 className="title is-2">What is a trust?</h2>
            <p>
              A trust is a document that is similar to a will, in that it
              directs how you would like your assets to pass after death. The
              purpose of having a trust is often to avoid probate, although
              there are other reasons as well. The most common kind of trust is
              called a &#8220;revocable living trust&#8221;. This name is often
              confused with &#8220;living will&#8221;, which is then often
              confused with &#8220;Last Will &amp; Testament.&#8221; They are
              not the same thing.
            </p>
            <h2 className="title is-2">Why do people need trusts?</h2>
            <p>
              The primary reason that people choose trusts is to avoid probate,
              although it is usually not the only way to avoid probate. However,
              aside from avoiding probate, there are several common situations
              where trusts are the best choice:
            </p>
            <ul className="fa-ul content">
              <li>
                <span className="fa-li">
                  <FaChevronCircleRight color="#A42E2D" />
                </span>
                People with real estate in more than one state use trusts to
                avoid having probate in multiple states.
              </li>
              <li>
                <span className="fa-li">
                  <FaChevronCircleRight color="#A42E2D" />
                </span>
                People who want to &#8220;control from the grave&#8221; choose
                trusts so that they can control how money is distributed after
                their deaths. For example, people can limit distributions to
                specific amounts at certain ages, or require that a beneficiary
                enroll in college, pass drug tests, stay out of the criminal
                system, etc&#8230;
              </li>
              <li>
                <span className="fa-li">
                  <FaChevronCircleRight color="#A42E2D" />
                </span>
                People who want to make charitable distributions choose trusts
                to simplify distributions.
              </li>
              <li>
                <span className="fa-li">
                  <FaChevronCircleRight color="#A42E2D" />
                </span>
                People who want to disinherit a child choose trusts to protect
                as much as possible against their wishes being contested.
              </li>
              <li>
                <span className="fa-li">
                  <FaChevronCircleRight color="#A42E2D" />
                </span>
                People with disabled family members choose trusts to provide for
                the disabled person while maintaining any government benefits
                the disabled person is receiving.
              </li>
              <li>
                <span className="fa-li">
                  <FaChevronCircleRight color="#A42E2D" />
                </span>
                People who want their documents to remain private choose trusts
                rather than having their Last Will and Testament go through
                probate and become public record.
              </li>
              <li>
                <span className="fa-li">
                  <FaChevronCircleRight color="#A42E2D" />
                </span>
                Married couples with sizeable estates choose trusts to minimize
                their federal estate tax.
              </li>
              <li>
                <span className="fa-li">
                  <FaChevronCircleRight color="#A42E2D" />
                </span>
                Married couples where one spouse is in a nursing home or
                expected to enter a nursing home choose trusts to obtain
                Medicaid benefits to pay for the nursing home care.
              </li>
            </ul>

            <h2 className="title is-2">
              Do I save or lose money with a trust?
            </h2>
            <p>
              Trusts cost more up front than other estate planning &#8211;
              typically around $2,000 &#8211; which includes a &#8220;complete
              estate planning package&#8221; of all other necessary documents
              (wills, financial powers of attorney, healthcare powers of
              attorney, and deeds to trust). However, the cost of probate can
              far exceed these costs.
            </p>
            <h2 className="title is-2">Do people like dealing with trusts?</h2>
            <p>
              People are getting very comfortable with trusts, and far prefer
              them to probating a will. Administering a trust is simpler, less
              expensive, and less time-consuming than probating an estate. Most
              people are happy with trusts, as long as they are appropriate in
              their situation and they understand how they work. Trusts are not
              right for everybody, but for many people, they save a great deal
              of time and money down the road.
            </p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default RevocableLivingTrusts
